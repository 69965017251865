import "./banner/Banner.css";
import Banner from "./banner/Banner";
import WeExpress from "./WeExpress/WeExpress";
import AllCategory from "./AllCategory/AllCategory";
import ShopByOccasion from "./ShopByOccasion/ShopByOccasion";
import PromotionalProduct from "./PromotionalProduct/PromotionalProduct";
import SustainableProduct from "./SustainableProduct/SustainableProduct";
import HomeAbout from "./HomeAbout/HomeAbout";
import SavingOffer from "./SavingOffer/SavingOffer";
import Customerlove from "./Customerlove/Customerlove";
import ArticlesInpiration from "./ArticlesInpiration/ArticlesInpiration";

function Home() {
  return (
    <>
      <Banner />
      <WeExpress />
      <AllCategory />
      <ShopByOccasion />
      <PromotionalProduct />
      <SustainableProduct />
      <HomeAbout />
      <SavingOffer />
      <Customerlove />
      <ArticlesInpiration />
    </>
  );
}

export default Home;
