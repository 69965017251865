import React, { useState } from 'react'
import "./Customerlove.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Rating } from 'react-simple-star-rating'
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
const Customerlove = () => {
    const [rating, setRating] = useState(0)
    const handleRating = (rate: number) => {
        setRating(rate)
    }
    const customerdata = [
        { id: 1, name: "Shekhar Rao" },
        { id: 1, name: "Shekhar Rao" },
        { id: 1, name: "Shekhar Rao" }
    ]


    return (
        <>
            <section className='CustomerLove'>
                <div className='container'>
                    <div className='customerHead'>
                        <h3>Customers Love Us</h3>
                        <p>Voices Of Delight: Our Customers Share Their Stories!</p>
                    </div>
                    <div className='customer-love-slider'>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            pagination={{
                                clickable: true,
                            }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 2,
                                    spaceBetween: 50,
                                },
                            }}
                            modules={[Pagination]}
                            className="mySwiper"
                        >
                            {customerdata?.map((customer, index) => (
                                <>
                                    <SwiperSlide>
                                        <div className='card customercard'>
                                            <div className='card-body'>
                                                <div className='row'>
                                                    <div className='col-lg-3'>
                                                        <div className='imgMainsec'>
                                                            <img src={process.env.PUBLIC_URL + "/assest/image/home/customerimage1.png"} />
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-9'>
                                                        <div className='cardContent'>
                                                            <h3>Shekhar Rao</h3>
                                                            <p>I am happy with the product easy to handle, carry and size is perfectly comfortable. Once you will buy this I am sure you water intake also enhance especially from this vessel.</p>

                                                            <Rating onClick={handleRating} initialValue={rating} size={20} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                </>
                            ))}

                        </Swiper>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Customerlove