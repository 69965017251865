import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './HomeAbout.css'
import CountUp from 'react-countup'
const HomeAbout = () => {
    return (
        <>
            <section className='home-about'>
                <Container>
                    <Row>
                        <Col lg={7}>
                            <div className='heading-holder'>
                                <div className='d-flex'>
                                    <h2 className='first-heading'>About  </h2>
                                    <h3 className='second-heading'>Us</h3>
                                </div>
                                <p className='text-para'>It took the vision of an innovator & the knowledge of experts to build</p>
                                <h2 className='text-content'>a virtual market where people could explore, discover & buy</h2>
                                <p className='text-para'>various kinds of genuine coins, with guaranteed peace of-mind, in their own luxurious confines.</p>
                                <div className='btn-holder'>
                                    <button className="btn btn-2 hover-slide-up">
                                        <span>Read More</span>
                                    </button>
                                </div>
                            </div>
                        </Col>
                        <Col lg={5}>
                            <Row>
                                <Col lg={6} md={6}>
                                    <div className='white-box mb-4'>
                                        <div className='total-holder text-center'>
                                            <h4><CountUp start={0} end={50} duration={4} decimals={0} /> K</h4>
                                            <p>Happy Customers</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} md={6}>
                                    <div className='white-box mb-4'>
                                        <div className='total-holder text-center'>
                                            <h4><CountUp start={0} end={1} duration={4} decimals={0} /> M+</h4>
                                            <p>Hrs Saved</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className='white-box'>
                                <div className='total-holder text-center '>
                                    <div className='d-flex justify-content-center'>
                                        <h4><CountUp start={0} end={50} duration={4} decimals={0} />M+</h4>
                                        <p className='ms-4 mt-lg-3 mt-md-2 mt-2'>Grateful Recipients</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default HomeAbout
