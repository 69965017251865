import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function Popular_Product_Card({
  content,
  image,
  imgClassName,
  className,
  textClassName,
  alt,
  overlaycolor,
  mainimagesec,
  icon,
}) {
  return (
    <>
      <div className={className}>
        {/* <div className={mainimagesec}> */}
        <img src={image} className={imgClassName} alt={alt} />
        {/* </div> */}
        <div className="text-holder">
          <p className={textClassName}>{content}</p>
          <FontAwesomeIcon icon={icon} className="fa-icon" />
        </div>
        <div className={overlaycolor}></div>


      </div>
    </>
  );
}

export default Popular_Product_Card;
