import React, { useState } from 'react';
import './Register.css';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const Register = () => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    return (
        <div className="login-container">
            <div className="login-box">
                <div className="login-header">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                        <Nav variant="pills">
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Create User Account</Nav.Link>
                                    </Nav.Item>

                                </div>
                                <div className='col-lg-6'>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Create Seller Account</Nav.Link>
                                    </Nav.Item>

                                </div>
                            </div>
                        </Nav>

                        <Tab.Content className='tablogin'>
                            <Tab.Pane eventKey="first">
                                <Form>
                                    <Form.Group controlId="formFirstName">
                                        <Form.Label className="emailtxt">First Name*</Form.Label>
                                        <Form.Control className='mb-3' type="text" placeholder="Enter Email or Mobile Number" />
                                    </Form.Group>

                                    <Form.Group controlId="formMiddleName">
                                        <Form.Label className="emailtxt">Middle Name / Initial</Form.Label>
                                        <div className='formfieldregister'>
                                            <Form.Control
                                                className='mb-3'
                                                type={showPassword ? "text" : "password"}
                                                placeholder="Enter Password"
                                            />

                                            <div className='eyeIcon' onClick={togglePasswordVisibility}>
                                                {showPassword ? '🙈' : '👁️'}
                                            </div>

                                        </div>


                                    </Form.Group>

                                    <Form.Group controlId="formFirstName">
                                        <Form.Label className="emailtxt">Last Name*</Form.Label>
                                        <Form.Control className='mb-3' type="text" placeholder="Enter Last Name" />
                                    </Form.Group>

                                    <Form.Group controlId="formFirstName">
                                        <Form.Label className="emailtxt">Email*</Form.Label>
                                        <Form.Control className='mb-3' type="text" placeholder="Enter Email" />
                                    </Form.Group>

                                    <Form.Group controlId="formFirstName">
                                        <Form.Label className="emailtxt">Password*</Form.Label>
                                        <div className='formfieldregister'>
                                            <Form.Control
                                                className='mb-3'
                                                type={showPassword ? "text" : "password"}
                                                placeholder="Enter Password"
                                            />

                                            <div className='eyeIcon' onClick={togglePasswordVisibility}>
                                                {showPassword ? '🙈' : '👁️'}
                                            </div>

                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="formFirstName">
                                        <Form.Label className="emailtxt">Confirm Password*</Form.Label>
                                        <div className='formfieldregister'>
                                            <Form.Control
                                                className='mb-3'
                                                type={showPassword ? "text" : "password"}
                                                placeholder="Enter Confirm Password"
                                            />

                                            <div className='eyeIcon' onClick={togglePasswordVisibility}>
                                                {showPassword ? '🙈' : '👁️'}
                                            </div>

                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="formFirstName">
                                        <Form.Label className="emailtxt">Contact*</Form.Label>
                                        <Form.Control className='mb-3' type="text" placeholder="Enter Contact" />
                                    </Form.Group>

                                    <Form.Group className="forgot-password">
                                        <Link href="#">Forgot Password?</Link>
                                    </Form.Group>
                                    <div className='text-center'>
                                        <Button className='createaccountbtn' type="submit">
                                            Create Account
                                        </Button>

                                    </div>
                                </Form>
                                <div className="signup-link">
                                    <p>By signing up, I agree to the <Link to={"/terms-condition"}>Terms and Conditions</Link></p>
                                    <p>Already have an account? <Link to={'/login'}>Login</Link></p>
                                </div>
                                <div className="social-login">
                                    <p>You can also login by using your</p>
                                    <div className="social-icons">
                                        <img src={process.env.PUBLIC_URL + "/assest/image/login/facebook.png"} alt="Google" />
                                        <img src={process.env.PUBLIC_URL + "/assest/image/login/google.png"} alt="Google" />
                                        <img src={process.env.PUBLIC_URL + "/assest/image/login/twitter.png"} alt="Google" />
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <Form>
                                    <Form.Group controlId="formFirstName">
                                        <Form.Label className="emailtxt">First Name*</Form.Label>
                                        <Form.Control className='mb-3' type="text" placeholder="Enter Email or Mobile Number" />
                                    </Form.Group>

                                    <Form.Group controlId="formMiddleName">
                                        <Form.Label className="emailtxt">Middle Name / Initial</Form.Label>
                                        <div className='formfieldregister'>
                                            <Form.Control
                                                className='mb-3'
                                                type={showPassword ? "text" : "password"}
                                                placeholder="Enter Password"
                                            />

                                            <div className='eyeIcon' onClick={togglePasswordVisibility}>
                                                {showPassword ? '🙈' : '👁️'}
                                            </div>

                                        </div>


                                    </Form.Group>

                                    <Form.Group controlId="formFirstName">
                                        <Form.Label className="emailtxt">Last Name*</Form.Label>
                                        <Form.Control className='mb-3' type="text" placeholder="Enter Last Name" />
                                    </Form.Group>

                                    <Form.Group controlId="formFirstName">
                                        <Form.Label className="emailtxt">Email*</Form.Label>
                                        <Form.Control className='mb-3' type="text" placeholder="Enter Email" />
                                    </Form.Group>

                                    <Form.Group controlId="formFirstName">
                                        <Form.Label className="emailtxt">Password*</Form.Label>
                                        <div className='formfieldregister'>
                                            <Form.Control
                                                className='mb-3'
                                                type={showPassword ? "text" : "password"}
                                                placeholder="Enter Password"
                                            />

                                            <div className='eyeIcon' onClick={togglePasswordVisibility}>
                                                {showPassword ? '🙈' : '👁️'}
                                            </div>

                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="formFirstName">
                                        <Form.Label className="emailtxt">Confirm Password*</Form.Label>
                                        <div className='formfieldregister'>
                                            <Form.Control
                                                className='mb-3'
                                                type={showPassword ? "text" : "password"}
                                                placeholder="Enter Confirm Password"
                                            />

                                            <div className='eyeIcon' onClick={togglePasswordVisibility}>
                                                {showPassword ? '🙈' : '👁️'}
                                            </div>

                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="formFirstName">
                                        <Form.Label className="emailtxt">Contact*</Form.Label>
                                        <Form.Control className='mb-3' type="text" placeholder="Enter Contact" />
                                    </Form.Group>

                                    <Form.Group className="forgot-password">
                                        <Link href="#">Forgot Password?</Link>
                                    </Form.Group>
                                    <div className='text-center'>
                                        <Button className='createaccountbtn' type="submit">
                                            Create Account
                                        </Button>

                                    </div>
                                </Form>
                                <div className="signup-link">
                                    <p>By signing up, I agree to the <Link href="#">Terms and Conditions</Link></p>
                                    <p>Already have an account? <Link to={'/login'}>Login</Link></p>
                                </div>
                                <div className="social-login">
                                    <p>You can also login by using your</p>
                                    <div className="social-icons">
                                        <img src={process.env.PUBLIC_URL + "/assest/image/login/facebook.png"} alt="Google" />
                                        <img src={process.env.PUBLIC_URL + "/assest/image/login/google.png"} alt="Google" />
                                        <img src={process.env.PUBLIC_URL + "/assest/image/login/twitter.png"} alt="Google" />
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>

                    </Tab.Container>
                </div>
            </div>
        </div>
    );
};

export default Register;
