import React from 'react';
import './Login.css';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Link } from 'react-router-dom';
const Login = () => {
    return (
        <div className="login-container">
            <div ></div>
            <div className="login-box">
                <div className="login-header">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                        <Nav variant="pills">
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">User Log-in</Nav.Link>
                                    </Nav.Item>

                                </div>
                                <div className='col-lg-6'>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Seller Log-in</Nav.Link>
                                    </Nav.Item>

                                </div>
                            </div>
                        </Nav>

                        <Tab.Content className='tablogin'>
                            <Tab.Pane eventKey="first">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="email" className='emailtxt'     >Email or Mobile Number</label>
                                        <input type="email" className="form-control" id="email" placeholder="Enter Email or Mobile Number" />
                                    </div>
                                    <div className="form-group">
                                        <label className='emailtxt' htmlFor="password">Password</label>
                                        <div className="password-container">
                                            <input type="password" className="form-control" id="password" placeholder="Enter Password" />
                                            <span className="password-icon">👁️</span>
                                        </div>
                                    </div>
                                    <div className="forgot-password">
                                        <a href="#">Forgot Password?</a>
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-block">Log-in</button>
                                </form>
                                <div className="signup-link">
                                    <p>Don't have an account? <Link to={"/register"}>Create Account</Link></p>
                                </div>
                                <div className="social-login">
                                    <p>You can also login by using your</p>
                                    <div className="social-icons">
                                        <img src={process.env.PUBLIC_URL + "/assest/image/login/facebook.png"} alt="Google" />
                                        <img src={process.env.PUBLIC_URL + "/assest/image/login/google.png"} alt="Google" />
                                        <img src={process.env.PUBLIC_URL + "/assest/image/login/twitter.png"} alt="Google" />
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <form>
                                    <div className="form-group">
                                        <label className='emailtxt' htmlFor="email">Email or Mobile Number</label>
                                        <input type="email" className="form-control" id="email" placeholder="Enter Email or Mobile Number" />
                                    </div>
                                    <div className="form-group">
                                        <label className='emailtxt' htmlFor="password">Password</label>
                                        <div className="password-container">
                                            <input type="password" className="form-control" id="password" placeholder="Enter Password" />
                                            <span className="password-icon">👁️</span>
                                        </div>
                                    </div>
                                    <div className="forgot-password">
                                        <a href="#">Forgot Password?</a>
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-block">Log-in</button>
                                </form>
                                <div className="signup-link">
                                    <p>Don't have an account? <a href="#">Create Account</a></p>
                                </div>
                                <div className="social-login">
                                    <p>You can also login by using your</p>
                                    <div className="social-icons">
                                        <img src={process.env.PUBLIC_URL + "/assest/image/login/facebook.png"} alt="Google" />
                                        <img src={process.env.PUBLIC_URL + "/assest/image/login/google.png"} alt="Google" />
                                        <img src={process.env.PUBLIC_URL + "/assest/image/login/twitter.png"} alt="Google" />
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>

                    </Tab.Container>
                </div>
            </div>
        </div>
    );
};

export default Login;
