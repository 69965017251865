import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Popular_Product_Card from '../../Common-Component/Popular_Product_Card/Popular_Product_Card'
import { Link } from 'react-router-dom'

const SustainableProduct = () => {
    return (
        <>
            <section className='promotional-product'>
                <Container>
                    <Row>
                        <div className='heading-holder mb-5'>
                            <div className='d-flex'>
                                <h2 className='first-heading'>Shop</h2>
                                <h3 className='second-heading'>Sustainable Products</h3>
                            </div>
                            <span className='text-para'>Empower Your Workspace: Stationery That Inspires Excellence</span>
                        </div>
                        <Col lg={6}>
                            <Popular_Product_Card
                                className="main-section "
                                image={process.env.PUBLIC_URL + "/assest/image/home/image-asse.png"}
                                imgClassName="seller-img"
                                content="VIEW ALL"
                                textClassName="local-text"
                                overlaycolor="bg-overlay"
                            />
                        </Col>
                        <Col lg={6}>
                            <Row>
                                <Col lg={6} md={6} sm={6} xs={6}>
                                    <Popular_Product_Card
                                        className="main-section "
                                        image={process.env.PUBLIC_URL + "/assest/image/home/image-jacket.png"}
                                        imgClassName="seller-img"
                                        overlaycolor="bg-overlay-one"
                                        mainimagesec="image-box"
                                        icon="fa-solid fa-arrow-right"
                                    />
                                    <Popular_Product_Card
                                        className="main-section "
                                        image={process.env.PUBLIC_URL + "/assest/image/home/image-mug.png"}
                                        imgClassName="seller-img"
                                        overlaycolor="bg-overlay-one"
                                        mainimagesec="image-box"
                                        icon="fa-solid fa-arrow-right"
                                    />

                                </Col>
                                <Col lg={6} md={6} sm={6} xs={6}>
                                    <Popular_Product_Card
                                        className="main-section "
                                        image={process.env.PUBLIC_URL + "/assest/image/home/iamge-lap.png"}
                                        imgClassName="seller-img"
                                        overlaycolor="bg-overlay-one"
                                        mainimagesec="image-box"
                                        icon="fa-solid fa-arrow-right"
                                    />
                                    <Popular_Product_Card
                                        className="main-section "
                                        image={process.env.PUBLIC_URL + "/assest/image/home/image-jacket.png"}
                                        imgClassName="seller-img"
                                        overlaycolor="bg-overlay-one"
                                        mainimagesec="image-box"
                                        icon="fa-solid fa-arrow-right"
                                    />
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default SustainableProduct
