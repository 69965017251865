import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import "./Editproflemodal.css";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
const Editproflemodal = (props) => {
    const [value, setValue] = useState();
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='editproflemodal'
            >

                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3 row" controlId="exampleForm.ControlInput1">
                            <div className='col-lg-2 mt-auto'>
                                <Form.Label className='mb-0'>
                                    <p className='mb-0'>Name</p>
                                </Form.Label>

                            </div>
                            <div className='col-lg-10'>
                                <Form.Control type="email" placeholder="Enter New Email Address" />

                            </div>
                        </Form.Group>


                        <div className='row'>
                            <div className='col-lg-2 mt-auto'>
                                <Form.Label className='mb-0'>
                                    <p className='mb-0'>Contact</p>
                                </Form.Label>

                            </div>
                            <div className='col-lg-10 '>
                                <PhoneInput
                                    country="in"
                                    value={value}
                                    onChange={setValue}

                                />

                            </div>
                        </div>

                        <div className='btnMainsec'>
                            <Button className='updatebtn'>Update</Button>
                            <Button className='savebtn'>Save</Button>

                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Editproflemodal