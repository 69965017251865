import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './SavingOffer.css'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
const SavingOffer = () => {
    const weExprss = [
        {
            headingtext: "Surprise Your Team with",
            headingtexttwo: "with Our Exciting Offers",
            text: "Best Corporate Gifts",
            image: process.env.PUBLIC_URL + "/assest/image/home/ban-shop.png",

        },
        {
            headingtext: "Surprise Your Team with",
            headingtexttwo: "with Our Exciting Offers",
            text: "Best Corporate Gifts",
            image: process.env.PUBLIC_URL + "/assest/image/home/ban-shop.png",

        },




    ];
    return (
        <>
            <section className='saving-offer'>
                <Container>
                    <Row>
                        <div className='heading-holder'>
                            <div className='d-flex'>
                                <h2 className='first-heading'>Super  </h2>
                                <h3 className='second-heading'>Saving Offers</h3>
                            </div>
                        </div>
                        <Swiper
                              spaceBetween={20}
                              slidesPerView={1}
                            pagination={{
                                dynamicBullets: true,
                            }}
                            modules={[Pagination]}
                            className="mySwiper"
                        >
                            {weExprss.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <div className='main-div mb-5'>
                                        <div className='image-holder'>
                                            <img src={item.image} className='bann-image' />
                                        </div>
                                        <div className='text-holder'>
                                            <h4>{item.headingtext}</h4>
                                            <h3>{item.text}</h3>
                                            <h4>{item.headingtexttwo}</h4>
                                        </div>
                                        <div className='overlay-color'></div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default SavingOffer
