import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './AllCategory.css'
const AllCategory = () => {
    return (
        <>
            <section className='all-category-home'>
                <Container>
                    <Row>
                        <Col lg={4} md={4} >
                            <Row>
                                <Col lg={6} md={6}>
                                    <div className='category-div cat-seecc-first'>
                                        <div className='image-holder'>
                                            <img className='category-image ' src={process.env.PUBLIC_URL + '/assest/image/home/image-1.png'} alt="Logo" />
                                        </div>
                                        <div className='text-holder'>
                                            <p>EMPLOYEE <br /> APPRECIATION</p>
                                        </div>
                                        <div className='overlay-color'></div>
                                    </div>
                                </Col>
                                <Col lg={6} md={6}>
                                    <div className='category-div cat-seecc-second mb-3'>
                                        <div className='image-holder'>
                                            <img className='category-image' src={process.env.PUBLIC_URL + '/assest/image/home/image-2.png'} alt="Logo" />
                                        </div>
                                        <div className='overlay-color'></div>
                                        <div className='text-holder'>
                                            <p>NEW  <br /> HIRING</p>
                                        </div>
                                    </div>
                                    <div className='category-div'>
                                        <div className='image-holder'>
                                            <img className='category-image ' src={process.env.PUBLIC_URL + '/assest/image/home/image-3.png'} alt="Logo" />
                                        </div>
                                        <div className='overlay-color'></div>
                                        <div className='text-holder'>
                                            <p>EMPLOYEE <br />  PERSONAL GIFTS</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} md={4}>
                            <div className='category-div mb-3'>
                                <div className='second-div'>
                                    <div className='image-holder'>
                                        <img className='category-image image-2' src={process.env.PUBLIC_URL + '/assest/image/home/image-4.png'} alt="Logo" />
                                    </div>
                                    <div className='overlay-color'></div>
                                    <div className='text-holder'>
                                        <p>FESTIVE  <br /> GIFTS</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={4}>
                            <Row>
                                <Col lg={6} md={6}>
                                    <div className='category-div cat-seecc-second mb-3'>
                                        <div className='image-holder'>
                                            <img className='category-image' src={process.env.PUBLIC_URL + '/assest/image/home/image-5.png'} alt="Logo" />
                                        </div>
                                        <div className='overlay-color'></div>
                                        <div className='text-holder'>
                                            <p>EMPLOYEE <br /> PERSONAL GIFTS</p>
                                        </div>
                                    </div>
                                    <div className='category-div'>
                                        <div className='image-holder'>
                                            <img className='category-image' src={process.env.PUBLIC_URL + '/assest/image/home/image-6.png'} alt="Logo" />
                                        </div>
                                        <div className='overlay-color'></div>
                                        <div className='text-holder'>
                                            <p>WEAR <br /> A SWAG</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} md={6}>
                                    <div className='category-div cat-seecc-first'>
                                        <div className='image-holder'>
                                            <img className='category-image' src={process.env.PUBLIC_URL + '/assest/image/home/image-7.png'} alt="Logo" />
                                        </div>
                                        <div className='overlay-color'></div>
                                        <div className='text-holder'>
                                            <p>TROPHYS <br /> AND MEDALS</p>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default AllCategory
