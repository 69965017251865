import React from 'react'
import "./ArticlesInpiration.css"
const ArticlesInpiration = () => {
    return (
        <>
            <section className='ArticlesInpiration'>
                <div className='container'>
                    <div className='articleshead'>
                        <h3>
                            <span>Articles & </span>
                            <span>Inspiration</span>
                        </h3>
                        <p>Where Ideas Flourish: Explore Our Blogverse!</p>
                    </div>

                    <div className='row'>
                        <div className='col-lg-8'>

                            <div className='articlesImg1'>
                                <img src={process.env.PUBLIC_URL + "/assest/image/home/articlesImg2.png"} />
                                <div className='overlay-color'></div>
                                <div className='imageinfo1'>
                                    <h3>Luxury Articles to Gift</h3>
                                    <p>Indulge in the art of gifting with our exquisite selection of luxury articles,<br /> meticulously curated to elevate any occasion.</p>
                                    <p>Read More</p>
                                </div>

                            </div>


                        </div>
                        <div className='col-lg-4'>
                            <div className='articlesImg2'>
                                <img src={process.env.PUBLIC_URL + "/assest/image/home/articlesImg1.png"} />
                                <div className='overlay-color'></div>
                                <div className='imageinfo2'>
                                    <h3>Antique Collections</h3>
                                    <p>Step into history's embrace with our captivating antique collections...</p>
                                    <p>Read More</p>
                                </div>
                            </div>

                            <div className='articlesImg2'>
                                <img src={process.env.PUBLIC_URL + "/assest/image/home/articlesImg1.png"} />
                                <div className='overlay-color'></div>
                                <div className='imageinfo2'>
                                    <h3>Gift Coins</h3>
                                    <p>Gift a piece of history with our timeless coin collections, each one a tangible...</p>
                                    <p>Read More</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ArticlesInpiration