import React from 'react';
import { Container, Row, Col, Card, Badge } from 'react-bootstrap';
import './MyOrders.css';

const orders = [
    {
        id: 1,
        image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
        name: 'Nike Dri-FIT Legend Tee - Men\'s',
        date: '3.JUN.24',
        status: 'Ordered',
        price: '0000'
    },
    {
        id: 2,
        image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
        name: 'White Coffee Mug',
        date: '2.JUN.24',
        status: 'Ordered',
        price: '0000'
    },
    {
        id: 3,
        image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
        name: 'Lather Cover Executive Diary and 2 more items',
        date: '1.JUN.24',
        status: 'Ordered',
        price: '0000'
    },
    {
        id: 4,
        image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
        name: 'Nike Dri-FIT Legend Tee - Men\'s',
        date: '25.MAY.24',
        status: 'Shipped',
        price: '0000'
    }
];

const MyOrders = () => {
    return (
        <section className='Myorders'>
            <Container className="my-4">
                <div className='myorderstitle'>
                    <h2>All Orders (11)</h2>
                    <hr class="divider"></hr>
                    <div className="order-status">
                        <span>Ordered (3)</span>
                        <span>Shipped (5)</span>
                        <span>Delivered (1)</span>
                        <span>Returned (1)</span>
                        <span>Canceled (1)</span>
                    </div>

                </div>
                <Row>
                    {orders.map(order => (
                        <Col key={order.id} xs={12} md={6} lg={3} className="my-3">
                            <Card className="order-card">
                                <Card.Img variant="top" src={order.image} />
                                <div className='orderedstatusMain'>
                                    <div className='orderedstatus' variant="info">{order.status}</div>

                                </div>
                                <Card.Body>
                                    <Card.Title>{order.name}</Card.Title>
                                    <Card.Text className='mb-0'>
                                        <div className='productPrice'>
                                            ₹ {order.price}

                                        </div>
                                    </Card.Text>
                                    <div className='Orderdate'>{order.date}</div>

                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>

        </section>
    );
};

export default MyOrders;
