import React from "react";
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./components/home/Home";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Welcomepage from "./components/Welcomepage/Welcomepage";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import Terms_condition from "./components/Terms_condition/Terms_condition";
import Profile from "./components/Profile/Profile";
import MyOrders from "./components/MyOrders/MyOrders";

function App() {
  const location = useLocation();
  const isWelcomePage = location.pathname === '/';
  const isAuthPage = location.pathname === '/login' || location.pathname === '/register';

  return (
    <>
      <ScrollToTop />
      {!isWelcomePage && !isAuthPage && <Header />}

      <Routes>
        <Route path="/" element={<Welcomepage />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/terms-condition" element={<Terms_condition />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/myOrders" element={<MyOrders />} />
      </Routes>
      {!isWelcomePage && !isAuthPage && <Footer />}
    </>
  );
}

export default App;
