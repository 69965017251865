import React, { useEffect, useRef } from 'react'
import './Welcomepage.css'
import { Link } from 'react-router-dom';
import Common_Button from '../Common-Component/Common_Button/Common_Button';
const Welcomepage = () => {
    const vidRef = useRef();

    useEffect(() => {
        vidRef.current.play();
    }, []);
    return (
        <>
            <section className='welcome-page'>
                <div className="video-main">
                    <video className="video-bg" src={process.env.PUBLIC_URL + "/assest/video/welcome-video.mp4"} ref={vidRef} muted loop controls />
                    <div className='black-overlay'> </div>
                    <div className='main-row-secccc'>
                        <div className='row me-0 ms-0 main-row'>
                            <div className='col-md-6 col-sm-6 px-0'>
                                <div className='heading-text text-center'>
                                    <h2>LUXURY GIFTS</h2>
                                    <div className='button-holder my-5'>
                                        <Link to="/home">  <Common_Button
                                            text="EXPLORE"
                                            className='explore-btn'
                                        /></Link>
                                    </div>
                                    <div className='text-holder mt-4'>
                                        <p>Handcrafted Luxury<br /> You Care About</p>
                                    </div>
                                    <div className='overlay-text-color1'></div>
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-6 px-0'>
                                <div className='heading-text1 text-center'>
                                    <h2>CORPORATE GIFTS</h2>
                                    <div className='button-holder my-5'>
                                        <Link to="/home">  <Common_Button
                                            text="EXPLORE"
                                            className='explore-btn'
                                        /></Link>
                                    </div>
                                    <div className='text-holder mt-4'>
                                        <p>Global All-In-One<br /> Gifting Solution</p>
                                    </div>
                                    <div className='overlay-text-color2'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='overflow-content'>
                    <div className='container'>
                        <div className="logo">
                            <Link to="/home">
                                <img className='headlogo' src={process.env.PUBLIC_URL + '/assest/image/logo/header-logo.png'} alt="Logo" />
                            </Link>
                        </div>
                    </div>
                </div>



            </section>
        </>
    )
}

export default Welcomepage
