import React from 'react'
import "./Terms_condition.css"
import { Button } from 'react-bootstrap'
import Common_Button from '../Common-Component/Common_Button/Common_Button'
const Terms_condition = () => {
    return (
        <>
            <section className='Terms_condition'>
                <div className='container'>
                    <div className='termsHeading'>
                        <h3>Terms & Conditions</h3>
                        <p>By consenting to the terms on this site (the “Website”), you are entering into the following Terms and Conditions established with MCW. PLEASE REVIEW THESE TERMS THOUGHTFULLY. If you disagree, please leave the site and refrain from further use.</p>
                    </div>

                    <div className='termsList'>
                        <h3>1. Definitions</h3>
                        <ul className='termslistItem' >
                            <li>**“Customer”** shall mean the corporate customer who places the order with MCW.</li>
                            <li>**“Products”** shall mean the products ordered by Customer to be delivered to the Recipient, as applicable.</li>
                            <li>**“Recipient”** shall, if an order is placed through the eGift site, mean the end person who receives the Product, either directly or through the re-gifting process.</li>
                        </ul>
                    </div>

                    <div className='termsList'>
                        <h3>2. Privacy</h3>
                        <ul className='termslistItem' >
                            <li>By accessing this Website, you are accepting MCW’s Privacy Policy, the details of which can be found at http://mcw.com/privacy-policy/.</li>

                        </ul>
                    </div>

                    <div className='termsList'>
                        <h3>3. Orders</h3>
                        <ul className='termslistItem' >
                            <p className='mb-0'>A customer makes a purchase on the MCW Website and either -</p>
                            <p>a) provides their own address for direct shipping, or b) provides the email address of the recipient.</p>
                            The recipient can either accept the product, opt to re-gift it to another recipient, or choose to "upgrade" to a different product available on the Website. All information you provide must be accurate, truthful, up-to-date, & complete. You assume all risks associated with unauthorized access and use related to your use of the Service and agree to promptly inform us if you discover or suspect any unauthorized access or use.

                        </ul>
                    </div>

                    <div className='termsList'>
                        <h3>4. Payment</h3>
                        <ul className='termslistItem' >
                            <li>The customer will complete payment upon placing the order, which includes taxes and shipping charges. Payments are handled by MCW's third-party payment processor, & the customer is accountable for reviewing all relevant terms and policies. MCW bears no responsibility for the actions or oversights of the payment processing entity.</li>

                        </ul>
                    </div>

                    <div className='buttonsection text-center'>
                        <Button className='declinebtn me-3'>Decline</Button>
                        <Button className='acceptbtn'>Accept</Button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Terms_condition